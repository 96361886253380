import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CheckBox = loadable(() => import('/src/components/Form/CheckBox'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const FormSection = loadable(() => import('/src/components/Form/FormSection'))
const Select = loadable(() => import('/src/components/Form/Select'))
const TextField = loadable(() => import('/src/components/Form/TextField'))
const Radio = loadable(() => import('/src/components/Form/Radio'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextAreaField = loadable(() => import('/src/components/Form/TextAreaField'))

const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email address is not valid').required('Email is required'),
    phone: Yup.string().max(15, 'Cannot be longer than 15 digits'),
    supportType: Yup.object({
        hearing: Yup.boolean(),
        vision: Yup.boolean(),
        call: Yup.boolean(),
        time: Yup.boolean()
    }).test('support-type-ok', function (value, { createError, path }) {
        if (value.hearing || value.vision || value.call || value.time) {
            return true // everything is fine
        }
        return createError({
            path,
            message: 'You must select at least one option'
        })
    }),
    hearing: Yup.object({
        commOptions: Yup.string().required('This field is required'),
        commType: Yup.string().when('commOptions', {
            is: 'yes',
            then: Yup.string().required('This field is required')
        }),
        commTypeOther: Yup.string().when('commType', {
            is: 'Other',
            then: Yup.string().required('This field is required')
        })
    }),
    vision: Yup.object({
        colouredPaper: Yup.boolean(),
        largePrint: Yup.boolean()
    }),
    call: Yup.object({
        shorter: Yup.string().required('This field is required'),
        samePerson: Yup.string().required('This field is required'),
        behalf: Yup.string().required('This field is required')
    }),
    time: Yup.object({
        extra: Yup.string().required('This field is required'),
        shorter: Yup.string().required('This field is required'),
        paperwork: Yup.string().required('This field is required')
    }),
    supportRequired: Yup.string()
})

const AccessibilityForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    const supportTypes = {
        hearing: 'Hearing and speech',
        vision: 'Vision and cognition',
        call: 'Multiple call process',
        time: 'Extra time for decision making'
    }

    const [isHearing, setHearing] = useState(false)
    const [isVision, setVision] = useState(false)
    const [isCall, setCall] = useState(false)
    const [isTime, setTime] = useState(false)

    const toggleHearing = () => {
        setHearing((prev) => !prev)
    }

    const toggleVision = () => {
        setVision((prev) => !prev)
    }

    const toggleCall = () => {
        setCall((prev) => !prev)
    }

    const toggleTime = () => {
        setTime((prev) => !prev)
    }

    // Hearing

    const commTypes = ['Relay UK', 'NRCPD Registered Interpreters', 'SignLive', 'SignVideo', 'Other']

    const [isCommType, setCommType] = useState(false)

    const toggleCommType = (event) => {
        const selected = event.target

        if (selected.checked && selected.value === 'yes') {
            setCommType(true)
        } else {
            setCommType(false)
        }
    }

    const [isCommTypeOther, setCommTypeOther] = useState(false)

    const toggleCommTypeOther = (event) => {
        const selected = event.target.value

        if (selected === 'Other') {
            setCommTypeOther(true)
        } else {
            setCommTypeOther(false)
        }
    }

    return (
        <Formik
            initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                supportType: {
                    hearing: false,
                    vision: false,
                    call: false,
                    time: false
                },
                hearing: {
                    commOptions: 'no',
                    commType: '',
                    commTypeOther: ''
                },
                vision: {
                    colouredPaper: false,
                    largePrint: false
                },
                call: {
                    shorter: 'no',
                    samePerson: 'no',
                    behalf: 'no'
                },
                time: {
                    extra: 'no',
                    shorter: 'no',
                    paperwork: 'no'
                },
                supportRequired: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email,
                    phone: values.phone,
                    supportType: {
                        hearing: values.supportType.hearing,
                        vision: values.supportType.vision,
                        call: values.supportType.call,
                        time: values.supportType.time
                    },
                    hearing: {
                        commOptions: values.hearing.commOptions,
                        commType: values.hearing.commType,
                        commTypeOther: values.hearing.commTypeOther
                    },
                    vision: {
                        colouredPaper: values.vision.colouredPaper,
                        largePrint: values.vision.largePrint
                    },
                    call: {
                        shorter: values.call.shorter,
                        samePerson: values.call.samePerson,
                        behalf: values.call.behalf
                    },
                    time: {
                        extra: values.time.extra,
                        shorter: values.time.shorter,
                        paperwork: values.time.paperwork
                    },
                    supportRequired: values.supportRequired
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'accessibility-form',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_ACCESSIBILITY_EMAIL_ADDRESS,
                    emailTemplate: 'accessibility-form-template'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FormRow>
                        <FieldSet>
                            <FieldSpacer grow={'half'}>
                                <TextField
                                    label={'First name'}
                                    name={'firstname'}
                                    placeholder={'e.g. Emma'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    required
                                />
                            </FieldSpacer>

                            <FieldSpacer grow={'half'}>
                                <TextField
                                    label={'Last name'}
                                    name={'lastname'}
                                    placeholder={'e.g. Jones'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    required
                                />
                            </FieldSpacer>
                        </FieldSet>
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Email address'}
                            name={'email'}
                            type={'email'}
                            placeholder={'e.g. name@email.com'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Phone number'}
                            name={'phone'}
                            format={'number'}
                            placeholder={'e.g. 07700900796'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </FormRow>

                    <FormRow>
                        <FieldSet
                            variant={'column'}
                            legend={'Type of support required:'}
                            groupName={'supportType'}
                            required
                        >
                            <FieldSpacer>
                                <CheckBox
                                    label={supportTypes.hearing}
                                    name={'supportType.hearing'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleHearing}
                                />
                            </FieldSpacer>

                            <FieldSpacer>
                                <CheckBox
                                    label={supportTypes.vision}
                                    name={'supportType.vision'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleVision}
                                />
                            </FieldSpacer>

                            <FieldSpacer>
                                <CheckBox
                                    label={supportTypes.call}
                                    name={'supportType.call'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleCall}
                                />
                            </FieldSpacer>

                            <FieldSpacer>
                                <CheckBox
                                    label={supportTypes.time}
                                    name={'supportType.time'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                    onChange={toggleTime}
                                />
                            </FieldSpacer>
                        </FieldSet>
                    </FormRow>

                    {isHearing && (
                        <FormSection title={supportTypes.hearing}>
                            <FormRow>
                                <FieldSet
                                    legend={'Do you require alternative communication options?'}
                                    groupName={'hearing.commOptions'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'hearing.commOptions'}
                                            name={'hearing.commOptions.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                            onChange={toggleCommType}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'hearing.commOptions'}
                                            name={'hearing.commOptions.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                            onChange={toggleCommType}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>

                            {isCommType && (
                                <>
                                    <FormRow>
                                        <Select
                                            label={'Please select your preferred communication option:'}
                                            name={'hearing.commType'}
                                            firstItem={'Please select'}
                                            data={commTypes}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                            onChange={toggleCommTypeOther}
                                            required
                                        />
                                    </FormRow>

                                    {isCommTypeOther && (
                                        <FormRow>
                                            <TextField
                                                label={'Please tell us your preferred communication option'}
                                                description={`You can recommend your preferred service to Protect Line and we'll be more than happy to consider adding them to our list of approved providers`}
                                                name={'hearing.commTypeOther'}
                                                isSubmitting={isSubmitting}
                                                isSubmitted={isSubmitted}
                                                required
                                            />
                                        </FormRow>
                                    )}
                                </>
                            )}
                        </FormSection>
                    )}

                    {isVision && (
                        <FormSection title={supportTypes.vision}>
                            <FormRow>
                                <FieldSet
                                    variant={'column'}
                                    legend={
                                        'Please select any alternate formats that you would you like to receive letters, statements and other communication in:'
                                    }
                                    groupName={'vision'}
                                >
                                    <FieldSpacer>
                                        <CheckBox
                                            label={'Coloured paper'}
                                            name={'vision.colouredPaper'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer>
                                        <CheckBox
                                            label={'Large print'}
                                            name={'vision.largePrint'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>
                        </FormSection>
                    )}

                    {isCall && (
                        <FormSection title={supportTypes.call}>
                            <FormRow>
                                <FieldSet
                                    legend={'Would you like to break our calls down into shorter calls?'}
                                    groupName={'call.shorter'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'call.shorter'}
                                            name={'call.shorter.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'call.shorter'}
                                            name={'call.shorter.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>

                            <FormRow>
                                <FieldSet
                                    legend={'Would you like to speak to the same person each time you call?'}
                                    groupName={'call.samePerson'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'call.samePerson'}
                                            name={'call.samePerson.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'call.samePerson'}
                                            name={'call.samePerson.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>

                            <FormRow>
                                <FieldSet
                                    legend={
                                        'Do you require a trusted family member or friend to communicate on your behalf?'
                                    }
                                    groupName={'call.behalf'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'call.behalf'}
                                            name={'call.behalf.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'call.behalf'}
                                            name={'call.behalf.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>
                        </FormSection>
                    )}

                    {isTime && (
                        <FormSection title={supportTypes.time}>
                            <FormRow>
                                <FieldSet
                                    legend={'Do you require extra time on the phone?'}
                                    groupName={'time.extra'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'time.extra'}
                                            name={'time.extra.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'time.extra'}
                                            name={'time.extra.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>

                            <FormRow>
                                <FieldSet
                                    legend={'Would you like to break our calls down into shorter calls?'}
                                    groupName={'time.shorter'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'time.shorter'}
                                            name={'time.shorter.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'time.shorter'}
                                            name={'time.shorter.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>

                            <FormRow>
                                <FieldSet
                                    legend={
                                        'Do you require extra time to review your paperwork to help with your decision?'
                                    }
                                    groupName={'time.paperwork'}
                                    required
                                >
                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'No'}
                                            groupName={'time.paperwork'}
                                            name={'time.paperwork.no'}
                                            value={'no'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>

                                    <FieldSpacer grow={'third'}>
                                        <Radio
                                            label={'Yes'}
                                            groupName={'time.paperwork'}
                                            name={'time.paperwork.yes'}
                                            value={'yes'}
                                            isSubmitting={isSubmitting}
                                            isSubmitted={isSubmitted}
                                        />
                                    </FieldSpacer>
                                </FieldSet>
                            </FormRow>
                        </FormSection>
                    )}

                    {(isHearing || isVision || isCall || isTime) && (
                        <FormSection>
                            <FormRow>
                                <TextAreaField
                                    label={'How can we support you?'}
                                    name={'supportRequired'}
                                    placeholder={'Please provide as much detail as possible...'}
                                    isSubmitting={isSubmitting}
                                    isSubmitted={isSubmitted}
                                />
                            </FormRow>
                        </FormSection>
                    )}

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Send enquiry'}
                            sentText={'Enquiry sent!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

AccessibilityForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

AccessibilityForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default AccessibilityForm
